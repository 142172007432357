export default function ObjectDetailsController($routeParams, $uibModal, skyDeepObjectService, logService) {
  this.objectId = $routeParams.id;
  this.data = {};

  this.loadData = () => {
    if (!this.objectId) {
      this.data = skyDeepObjectService.empty();
    } else {
      skyDeepObjectService.get(this.objectId).then(response => {
        if (this.data !== response) {
          angular.copy(response, this.data);
        }
      }, reason => {
        logService.displayErrorMessage(reason, 'The Deep Sky Object you requested was not found.');
      });
    }
  };

  this.popImage = () => {
    let imageModal = $uibModal.open({
        templateUrl: "templates/image-lightbox.html",
        controller: "imageModalController",
        controllerAs: "image",
        windowClass: "confirm-modal",
        resolve: {
          url: () => this.data.object.imageUrl
        }
    }).result.then(() => {}, (res) => {});
  };

  this.popChart = () => {
    let imageModal = $uibModal.open({
        templateUrl: "templates/image-lightbox.html",
        controller: "imageModalController",
        controllerAs: "image",
        windowClass: "confirm-modal",
        resolve: {
          url: () => this.data.object.chartUrl
        }
    }).result.then(() => {}, (res) => {});
  };

  this.loadData();
}
