export function NavigationPrimary() {
  return {
    restrict: 'A',
    templateUrl: 'templates/nav-main.html'
  };
}

export function AsLogoutDirective(identityService) {
  return {
    restrict: 'A',
    link: (scope, element, attrs) => {
      element.bind('click', () => {
        let auth = identityService.logout();
      });
    }
  };
}

export function PasswordVerify() {
  return {
    restrict: 'A',
    require: '?ngModel',
    link: function(scope, elem, attrs, ngModel) {
      if (!ngModel) return; // do nothing if no ng-model

      // watch own value and re-validate on change
      scope.$watch(attrs.ngModel, function() {
        validate();
      });

      // observe the other value and re-validate on change
      attrs.$observe('passwordVerify', function(val) {
        validate();
      });

      var validate = function() {
        // values
        var val1 = ngModel.$viewValue;
        var val2 = attrs.passwordVerify;

        // set validity
        ngModel.$setValidity('passwordVerify', val1 === val2);
      };
    }
  };
}

export function StartFromFilter() {
  return (input, start) => {
    if (input && input.length > 0) {
      start = +start;
      return input.slice(start);
    }

    return [];
  };
}
