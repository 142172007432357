export default function RegisterController($q, $location, identityService) {
    this.credentials = {
      persistent: false,
      email: '',
      fullName: '',
      password: '',
      confirmPassword: '',
    };
  
    this.register = () => {
      let registration = identityService.register({
        email: this.credentials.email,
        fullName: this.credentials.fullName,
        password: this.credentials.password
      });
  
      $q.when(registration).then(() => {
        $location.path('/account/login');
      });
    };
  }
  