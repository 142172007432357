export default function DeepObjectService(skyDeepObjectApi, logService, orbMessageService, orbProgressService) {
  return {
    empty: () => {
      return skyDeepObjectApi.empty();
    },

    all: (catalog) => {
      let defer = skyDeepObjectApi.all({ catalog: catalog });
      return defer;
    },

    get: (id) => {
      let defer = skyDeepObjectApi.find(id);
      return defer;
    },

    create: (object) => {
      let defer = skyDeepObjectApi.save(object).then((response) => {
        orbMessageService.success('New deep sky object successfully created.');
        return response;
      }, (reason) => {
        logService.displayErrorMessage(reason, 'Error while creating new deep sky object.');
      });

      return defer;
    }
  };
}
