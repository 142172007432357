export default function CaldwellListController($filter, skyDeepObjectService, skyLookupService, logService) {
  this.currentPage = 1;
  this.lookups = skyLookupService;

  this.pagingDisabled = false;

  skyDeepObjectService.all('Caldwell').then(response => {
    this.data = response;
  }, reason => {
    logService.displayErrorMessage(reason, 'The Caldwell Catalog objects could not be loaded.');
  });
}
