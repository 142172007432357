export function IdentityApi(orbRestfulResource, orbRestfulProgressService) {
  let res = new orbRestfulResource('/api/account/identity', {}, {query: {isArray: false}});
  return new orbRestfulProgressService(res);
}

export function RegistrationApi() {
 return {
   $get: $resource => {
     return $resource('/api/account', {}, {
       register: { method: 'POST' }
     });
   }
 };
}

export function AuthenticationApi() {
  return {
    $get: $resource => {
      return $resource('/api/account/auth', {}, {
        login: { method: 'POST' },
        logout: { method: 'DELETE' }
      });
    }
  };
}

export function DeepObjectApi(orbRestfulResource, orbRestfulProgressService) {
  let res = new orbRestfulResource('/api/deepobject/:id', {}, {query: {isArray: false}});
  return new orbRestfulProgressService(res);
}

export function ObservationApi(orbRestfulResource, orbRestfulProgressService) {
  let res = new orbRestfulResource('/api/observation/:id', {}, {query: {isArray: false}});
  return new orbRestfulProgressService(res);
}

export function DataIoApi(orbRestfulResource, orbRestfulProgressService) {
  let res = new orbRestfulResource('/api/write-disk', {}, {query: {isArray: false}});
  return new orbRestfulProgressService(res);
}
