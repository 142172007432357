export default function ObservationEditController($routeParams, $location, skyDeepObjectService, skyObservationService, skyLookupService, logService) {
  this.lookups = skyLookupService;

  this.objectId = $routeParams.objectId;
  this.observationId = $routeParams.id;

  this.datePopup = {
    opened: false
  };

  this.dateOpen = () => {
    this.datePopup.opened = true;
  };

  this.save = () => {
    let selectedTrans = this.lookups.transparencies.filter(item => item.value === this.data.transparency);
    let selectedSeeing = this.lookups.seeings.filter(item => item.value === this.data.seeing);

    this.data.transparencyLabel = selectedTrans[0].label;
    this.data.seeingLabel = selectedSeeing[0].label;

    if (!this.data.id) {
      skyObservationService.create(this.data).then((response) => {
        console.log(response);

        let pathPaths = $location.path().split("/observation");
        $location.path(pathPaths[0]);
      });
    } else {
      skyObservationService.save(this.data).then((response) => {
        console.log(response);

        let pathPaths = $location.path().split("/observation");
        $location.path(pathPaths[0]);
      });
    }
  };

  skyDeepObjectService.get(this.objectId).then((response) => {
    this.object = response;
  });

  if (!this.observationId) {
    // create mode
    this.data = {
      date: new Date(),
      rating: 3,
      objectId: Number(this.objectId)
    };
  } else {
    // edit mode
    skyObservationService.get(this.observationId).then(response => {
      this.data = response;
      this.data.date = new Date(response.date);
    }, reason => {
      logService.displayErrorMessage(reason, "Unable to load observation information.");
      console.log(reason);
    });
  }

  let initalRouteParts = $location.path().split("/").filter(part => !!part);
  this.catalog = initalRouteParts[0];
}
