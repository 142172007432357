export default function RouteProvider($routeProvider, $locationProvider) {

  $routeProvider
    .when('/', {
      templateUrl:  'templates/welcome.html'
    })
    .when('/account/login', {
      templateUrl:  'templates/login.html',
      controller:   'loginController',
      controllerAs: 'loginController'
    })
    .when('/account/register', {
      templateUrl:  'templates/register.html',
      controller:   'registerController',
      controllerAs: 'registerController'
    })
    .when('/object/create', {
      templateUrl:  'templates/object-edit.html',
      controller:   'skyObjectEditController',
      controllerAs: 'edit'
    })
    .when('/messier', {
      templateUrl:  'templates/messier-list.html',
      controller:   'skyMessierListController',
      controllerAs: 'messierController'
    })
    .when('/messier/:id', {
      templateUrl:  'templates/messier-details.html',
      controller:   'skyObjectDetailsController',
      controllerAs: 'objectController'
    })
    .when('/messier/:objectId/observation/:id?', {
      templateUrl:  'templates/observation-edit.html',
      controller:   'skyObservationEditController',
      controllerAs: 'observationController'
    })
    .when('/caldwell', {
      templateUrl:  'templates/caldwell-list.html',
      controller:   'skyCaldwellListController',
      controllerAs: 'caldwellController'
    })
    .when('/caldwell/:id', {
      templateUrl:  'templates/caldwell-details.html',
      controller:   'skyObjectDetailsController',
      controllerAs: 'objectController'
    })
    .when('/caldwell/:objectId/observation/:id?', {
      templateUrl:  'templates/observation-edit.html',
      controller:   'skyObservationEditController',
      controllerAs: 'observationController'
    })
    .when('/herschel', {
      templateUrl:  'templates/herschel-list.html',
      controller:   'skyHerschelListController',
      controllerAs: 'herschelController'
    })
    .when('/herschel/:id', {
      templateUrl:  'templates/herschel-details.html',
      controller:   'skyObjectDetailsController',
      controllerAs: 'objectController'
    })
    .when('/herschel/:objectId/observation/:id?', {
      templateUrl:  'templates/observation-edit.html',
      controller:   'skyObservationEditController',
      controllerAs: 'observationController'
    })
    .when('/solarsystem', {
      templateUrl:  'templates/solar-list.html',
      controller:   'skySolarListController',
      controllerAs: 'solarController'
    })
    .when('/solarsystem/:id', {
      templateUrl:  'templates/solar-details.html',
      controller:   'skyObjectDetailsController',
      controllerAs: 'objectController'
    })
    .when('/solarsystem/:objectId/observation/:id?', {
      templateUrl:  'templates/observation-edit.html',
      controller:   'skyObservationEditController',
      controllerAs: 'observationController'
    })
    .when('/observations', {
      templateUrl:  'templates/observations.html',
      controller:   'skyObservationsController',
      controllerAs: 'observationController'
    });

  $locationProvider.html5Mode(true);
}
