import RouteProvider from './configuration';

import * as resources from './resources';
import * as directives from './directives';

import IdentityController from './controllers/identity';
import LoginController from './controllers/login';
import RegisterController from './controllers/register';
import MessierListController from './controllers/messier-list';
import CaldwellListController from './controllers/caldwell-list';
import HerschelListController from './controllers/herschel-list';
import SolarListController from './controllers/solar-list';
import ObjectDetailsController from './controllers/object-details';
import ObservationsController from './controllers/observations';
import ObservationEditController from './controllers/observation-edit';
import ObjectEditController from './controllers/object-edit';
import ImageModalController from './controllers/image-modal';

import IdentityService from './services/identity';
import LogService from './services/log';
import DeepObjectService from './services/deep-object';
import ObservationService from './services/observation';
import LookupService from './services/lookup';

export default angular
  .module('sky', ['http-auth-interceptor', 'ngAnimate', 'ngRoute', 'ngResource', 'ui.bootstrap', 'orbMessage', 'orbProgress', 'orbResource'])
  .config(RouteProvider)
  .controller('loginController', LoginController)
  .controller('registerController', RegisterController)
  .controller('identityController', IdentityController)
  .controller('skyMessierListController', MessierListController)
  .controller('skyCaldwellListController', CaldwellListController)
  .controller('skyHerschelListController', HerschelListController)
  .controller('skySolarListController', SolarListController)
  .controller('skyObjectDetailsController', ObjectDetailsController)
  .controller('skyObjectEditController', ObjectEditController)
  .controller('skyObservationsController', ObservationsController)
  .controller('skyObservationEditController', ObservationEditController)
  .controller('imageModalController', ImageModalController)
  .factory('identityService', IdentityService)
  .factory('logService', LogService)
  .factory('skyDeepObjectService', DeepObjectService)
  .factory('skyObservationService', ObservationService)
  .factory('skyLookupService', LookupService)
  .factory('skyIdentityApi', resources.IdentityApi)
  .provider('skyRegistrationApi', resources.RegistrationApi)
  .provider('skyAuthenticationApi', resources.AuthenticationApi)
  .factory('skyDeepObjectApi', resources.DeepObjectApi)
  .factory('skyObservationApi', resources.ObservationApi)
  .factory('skyPersistenceApi', resources.DataIoApi)
  .directive('navPrimary', directives.NavigationPrimary)
  .directive('asLogout', directives.AsLogoutDirective)
  .directive('passwordVerify', directives.PasswordVerify)
  .filter('startFrom', directives.StartFromFilter);
