export default function ObjectEditController($location, skyDeepObjectService, skyLookupService, logService) {
  this.lookups = skyLookupService;
  this.declination = {
    degrees: "",
    arcminutes: "",
    arcseconds: ""
  };
  this.object = {};

  this.save = () => {
    this.object.declination = !!(Number(this.declination.arcseconds)) ? `${this.declination.degrees}° ${this.declination.arcminutes}’ ${this.declination.arcseconds}”` : `${this.declination.degrees}° ${this.declination.arcminutes}’`;

    skyDeepObjectService.create(this.object).then((response) => {
      if (response) {
        console.log(response);
        $location.path(`/${response.catalog.toLowerCase()}/${response.id}`);
      }
    });
  };
}
