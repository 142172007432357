(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _configuration = require('./configuration');

var _configuration2 = _interopRequireDefault(_configuration);

var _resources = require('./resources');

var resources = _interopRequireWildcard(_resources);

var _directives = require('./directives');

var directives = _interopRequireWildcard(_directives);

var _identity = require('./controllers/identity');

var _identity2 = _interopRequireDefault(_identity);

var _login = require('./controllers/login');

var _login2 = _interopRequireDefault(_login);

var _register = require('./controllers/register');

var _register2 = _interopRequireDefault(_register);

var _messierList = require('./controllers/messier-list');

var _messierList2 = _interopRequireDefault(_messierList);

var _caldwellList = require('./controllers/caldwell-list');

var _caldwellList2 = _interopRequireDefault(_caldwellList);

var _herschelList = require('./controllers/herschel-list');

var _herschelList2 = _interopRequireDefault(_herschelList);

var _solarList = require('./controllers/solar-list');

var _solarList2 = _interopRequireDefault(_solarList);

var _objectDetails = require('./controllers/object-details');

var _objectDetails2 = _interopRequireDefault(_objectDetails);

var _observations = require('./controllers/observations');

var _observations2 = _interopRequireDefault(_observations);

var _observationEdit = require('./controllers/observation-edit');

var _observationEdit2 = _interopRequireDefault(_observationEdit);

var _objectEdit = require('./controllers/object-edit');

var _objectEdit2 = _interopRequireDefault(_objectEdit);

var _imageModal = require('./controllers/image-modal');

var _imageModal2 = _interopRequireDefault(_imageModal);

var _identity3 = require('./services/identity');

var _identity4 = _interopRequireDefault(_identity3);

var _log = require('./services/log');

var _log2 = _interopRequireDefault(_log);

var _deepObject = require('./services/deep-object');

var _deepObject2 = _interopRequireDefault(_deepObject);

var _observation = require('./services/observation');

var _observation2 = _interopRequireDefault(_observation);

var _lookup = require('./services/lookup');

var _lookup2 = _interopRequireDefault(_lookup);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = angular.module('sky', ['http-auth-interceptor', 'ngAnimate', 'ngRoute', 'ngResource', 'ui.bootstrap', 'orbMessage', 'orbProgress', 'orbResource']).config(_configuration2.default).controller('loginController', _login2.default).controller('registerController', _register2.default).controller('identityController', _identity2.default).controller('skyMessierListController', _messierList2.default).controller('skyCaldwellListController', _caldwellList2.default).controller('skyHerschelListController', _herschelList2.default).controller('skySolarListController', _solarList2.default).controller('skyObjectDetailsController', _objectDetails2.default).controller('skyObjectEditController', _objectEdit2.default).controller('skyObservationsController', _observations2.default).controller('skyObservationEditController', _observationEdit2.default).controller('imageModalController', _imageModal2.default).factory('identityService', _identity4.default).factory('logService', _log2.default).factory('skyDeepObjectService', _deepObject2.default).factory('skyObservationService', _observation2.default).factory('skyLookupService', _lookup2.default).factory('skyIdentityApi', resources.IdentityApi).provider('skyRegistrationApi', resources.RegistrationApi).provider('skyAuthenticationApi', resources.AuthenticationApi).factory('skyDeepObjectApi', resources.DeepObjectApi).factory('skyObservationApi', resources.ObservationApi).factory('skyPersistenceApi', resources.DataIoApi).directive('navPrimary', directives.NavigationPrimary).directive('asLogout', directives.AsLogoutDirective).directive('passwordVerify', directives.PasswordVerify).filter('startFrom', directives.StartFromFilter);

},{"./configuration":2,"./controllers/caldwell-list":3,"./controllers/herschel-list":4,"./controllers/identity":5,"./controllers/image-modal":6,"./controllers/login":7,"./controllers/messier-list":8,"./controllers/object-details":9,"./controllers/object-edit":10,"./controllers/observation-edit":11,"./controllers/observations":12,"./controllers/register":13,"./controllers/solar-list":14,"./directives":15,"./resources":16,"./services/deep-object":17,"./services/identity":18,"./services/log":19,"./services/lookup":20,"./services/observation":21}],2:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RouteProvider;
function RouteProvider($routeProvider, $locationProvider) {

  $routeProvider.when('/', {
    templateUrl: 'templates/welcome.html'
  }).when('/account/login', {
    templateUrl: 'templates/login.html',
    controller: 'loginController',
    controllerAs: 'loginController'
  }).when('/account/register', {
    templateUrl: 'templates/register.html',
    controller: 'registerController',
    controllerAs: 'registerController'
  }).when('/object/create', {
    templateUrl: 'templates/object-edit.html',
    controller: 'skyObjectEditController',
    controllerAs: 'edit'
  }).when('/messier', {
    templateUrl: 'templates/messier-list.html',
    controller: 'skyMessierListController',
    controllerAs: 'messierController'
  }).when('/messier/:id', {
    templateUrl: 'templates/messier-details.html',
    controller: 'skyObjectDetailsController',
    controllerAs: 'objectController'
  }).when('/messier/:objectId/observation/:id?', {
    templateUrl: 'templates/observation-edit.html',
    controller: 'skyObservationEditController',
    controllerAs: 'observationController'
  }).when('/caldwell', {
    templateUrl: 'templates/caldwell-list.html',
    controller: 'skyCaldwellListController',
    controllerAs: 'caldwellController'
  }).when('/caldwell/:id', {
    templateUrl: 'templates/caldwell-details.html',
    controller: 'skyObjectDetailsController',
    controllerAs: 'objectController'
  }).when('/caldwell/:objectId/observation/:id?', {
    templateUrl: 'templates/observation-edit.html',
    controller: 'skyObservationEditController',
    controllerAs: 'observationController'
  }).when('/herschel', {
    templateUrl: 'templates/herschel-list.html',
    controller: 'skyHerschelListController',
    controllerAs: 'herschelController'
  }).when('/herschel/:id', {
    templateUrl: 'templates/herschel-details.html',
    controller: 'skyObjectDetailsController',
    controllerAs: 'objectController'
  }).when('/herschel/:objectId/observation/:id?', {
    templateUrl: 'templates/observation-edit.html',
    controller: 'skyObservationEditController',
    controllerAs: 'observationController'
  }).when('/solarsystem', {
    templateUrl: 'templates/solar-list.html',
    controller: 'skySolarListController',
    controllerAs: 'solarController'
  }).when('/solarsystem/:id', {
    templateUrl: 'templates/solar-details.html',
    controller: 'skyObjectDetailsController',
    controllerAs: 'objectController'
  }).when('/solarsystem/:objectId/observation/:id?', {
    templateUrl: 'templates/observation-edit.html',
    controller: 'skyObservationEditController',
    controllerAs: 'observationController'
  }).when('/observations', {
    templateUrl: 'templates/observations.html',
    controller: 'skyObservationsController',
    controllerAs: 'observationController'
  });

  $locationProvider.html5Mode(true);
}

},{}],3:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CaldwellListController;
function CaldwellListController($filter, skyDeepObjectService, skyLookupService, logService) {
  var _this = this;

  this.currentPage = 1;
  this.lookups = skyLookupService;

  this.pagingDisabled = false;

  skyDeepObjectService.all('Caldwell').then(function (response) {
    _this.data = response;
  }, function (reason) {
    logService.displayErrorMessage(reason, 'The Caldwell Catalog objects could not be loaded.');
  });
}

},{}],4:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HerschelListController;
function HerschelListController($filter, skyDeepObjectService, skyLookupService, logService) {
  var _this = this;

  this.currentPage = 1;
  this.lookups = skyLookupService;

  this.pagingDisabled = false;

  skyDeepObjectService.all('Herschel').then(function (response) {
    _this.data = response;
    _this.filtered = response.objects || [];
  }, function (reason) {
    logService.displayErrorMessage(reason, 'The Herschel Catalog objects could not be loaded.');
  });
}

},{}],5:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = IdentityController;
function IdentityController($scope, $log, $uibModal, identityService) {
  var pendingLogin = false;

  this.user = identityService.data;
  identityService.ensure();

  $scope.$on('event:auth-loginRequired', function (event, args) {
    if (!pendingLogin) {
      pendingLogin = true;
      showLogin();
    }
  });

  $scope.$on('event:auth-loginConfirmed', function (event, args) {
    $log.info('Login confirmed');
    pendingLogin = false;
    identityService.refresh();
  });

  $scope.$on('event:auth-loginCancelled', function (event, args) {
    $log.info('Login cancelled');
    pendingLogin = false;
  });

  var showLogin = function showLogin() {
    pendingLogin = true; // just in case somebody bypasses the 401 auth event and uses the Login button

    var loginModalInstance = $uibModal.open({
      templateUrl: 'templates/login-modal.html',
      controller: 'skyLoginModalController',
      controllerAs: 'loginController',
      windowClass: 'login-modal',
      backdrop: 'static',
      keyboard: false
    });

    return loginModalInstance.result;
  };
}

},{}],6:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ImageModalController;
function ImageModalController($uibModalInstance, url) {
  var _this = this;

  this.url = url;

  this.ok = function () {
    return $uibModalInstance.close(_this.url);
  };
}

},{}],7:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = LoginController;
function LoginController($q, $location, identityService) {
  var _this = this;

  this.credentials = {
    persistent: false,
    email: '',
    password: ''
  };

  this.login = function () {
    var auth = identityService.login({
      email: _this.credentials.email,
      password: _this.credentials.password,
      persistent: _this.credentials.persistent || false
    });

    $q.when(auth).then(function () {
      $location.path('/');
    });
  };
}

},{}],8:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = MessierListController;
function MessierListController($filter, skyDeepObjectService, skyLookupService, logService) {
  var _this = this;

  this.currentPage = 1;
  this.lookups = skyLookupService;

  this.pagingDisabled = false;

  skyDeepObjectService.all('Messier').then(function (response) {
    _this.data = response;
    _this.filtered = response.objects || [];
  }, function (reason) {
    logService.displayErrorMessage(reason, 'The Messier Catalog objects could not be loaded.');
  });
}

},{}],9:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ObjectDetailsController;
function ObjectDetailsController($routeParams, $uibModal, skyDeepObjectService, logService) {
  var _this = this;

  this.objectId = $routeParams.id;
  this.data = {};

  this.loadData = function () {
    if (!_this.objectId) {
      _this.data = skyDeepObjectService.empty();
    } else {
      skyDeepObjectService.get(_this.objectId).then(function (response) {
        if (_this.data !== response) {
          angular.copy(response, _this.data);
        }
      }, function (reason) {
        logService.displayErrorMessage(reason, 'The Deep Sky Object you requested was not found.');
      });
    }
  };

  this.popImage = function () {
    var imageModal = $uibModal.open({
      templateUrl: "templates/image-lightbox.html",
      controller: "imageModalController",
      controllerAs: "image",
      windowClass: "confirm-modal",
      resolve: {
        url: function url() {
          return _this.data.object.imageUrl;
        }
      }
    }).result.then(function () {}, function (res) {});
  };

  this.popChart = function () {
    var imageModal = $uibModal.open({
      templateUrl: "templates/image-lightbox.html",
      controller: "imageModalController",
      controllerAs: "image",
      windowClass: "confirm-modal",
      resolve: {
        url: function url() {
          return _this.data.object.chartUrl;
        }
      }
    }).result.then(function () {}, function (res) {});
  };

  this.loadData();
}

},{}],10:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ObjectEditController;
function ObjectEditController($location, skyDeepObjectService, skyLookupService, logService) {
  var _this = this;

  this.lookups = skyLookupService;
  this.declination = {
    degrees: "",
    arcminutes: "",
    arcseconds: ""
  };
  this.object = {};

  this.save = function () {
    _this.object.declination = !!Number(_this.declination.arcseconds) ? _this.declination.degrees + "\xB0 " + _this.declination.arcminutes + "\u2019 " + _this.declination.arcseconds + "\u201D" : _this.declination.degrees + "\xB0 " + _this.declination.arcminutes + "\u2019";

    skyDeepObjectService.create(_this.object).then(function (response) {
      if (response) {
        console.log(response);
        $location.path("/" + response.catalog.toLowerCase() + "/" + response.id);
      }
    });
  };
}

},{}],11:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ObservationEditController;
function ObservationEditController($routeParams, $location, skyDeepObjectService, skyObservationService, skyLookupService, logService) {
  var _this = this;

  this.lookups = skyLookupService;

  this.objectId = $routeParams.objectId;
  this.observationId = $routeParams.id;

  this.datePopup = {
    opened: false
  };

  this.dateOpen = function () {
    _this.datePopup.opened = true;
  };

  this.save = function () {
    var selectedTrans = _this.lookups.transparencies.filter(function (item) {
      return item.value === _this.data.transparency;
    });
    var selectedSeeing = _this.lookups.seeings.filter(function (item) {
      return item.value === _this.data.seeing;
    });

    _this.data.transparencyLabel = selectedTrans[0].label;
    _this.data.seeingLabel = selectedSeeing[0].label;

    if (!_this.data.id) {
      skyObservationService.create(_this.data).then(function (response) {
        console.log(response);

        var pathPaths = $location.path().split("/observation");
        $location.path(pathPaths[0]);
      });
    } else {
      skyObservationService.save(_this.data).then(function (response) {
        console.log(response);

        var pathPaths = $location.path().split("/observation");
        $location.path(pathPaths[0]);
      });
    }
  };

  skyDeepObjectService.get(this.objectId).then(function (response) {
    _this.object = response;
  });

  if (!this.observationId) {
    // create mode
    this.data = {
      date: new Date(),
      rating: 3,
      objectId: Number(this.objectId)
    };
  } else {
    // edit mode
    skyObservationService.get(this.observationId).then(function (response) {
      _this.data = response;
      _this.data.date = new Date(response.date);
    }, function (reason) {
      logService.displayErrorMessage(reason, "Unable to load observation information.");
      console.log(reason);
    });
  }

  var initalRouteParts = $location.path().split("/").filter(function (part) {
    return !!part;
  });
  this.catalog = initalRouteParts[0];
}

},{}],12:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ObservationsController;
function ObservationsController(skyObservationService, logService) {
  var _this = this;

  this.currentPage = 1;
  this.pagingDisabled = false;

  skyObservationService.all().then(function (response) {
    _this.data = response.observations;
    _this.filtered = response.observations || [];
  }, function (reason) {
    logService.displayErrorMessage(reason, 'Your observations could not be loaded.');
  });
}

},{}],13:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RegisterController;
function RegisterController($q, $location, identityService) {
  var _this = this;

  this.credentials = {
    persistent: false,
    email: '',
    fullName: '',
    password: '',
    confirmPassword: ''
  };

  this.register = function () {
    var registration = identityService.register({
      email: _this.credentials.email,
      fullName: _this.credentials.fullName,
      password: _this.credentials.password
    });

    $q.when(registration).then(function () {
      $location.path('/account/login');
    });
  };
}

},{}],14:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SolarListController;
function SolarListController($filter, skyDeepObjectService, skyLookupService, logService) {
  var _this = this;

  this.currentPage = 1;
  this.lookups = skyLookupService;

  this.pagingDisabled = false;

  skyDeepObjectService.all('Solar').then(function (response) {
    _this.data = response;
    _this.filtered = response.objects || [];
  }, function (reason) {
    logService.displayErrorMessage(reason, 'The Solar System objects could not be loaded.');
  });
}

},{}],15:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NavigationPrimary = NavigationPrimary;
exports.AsLogoutDirective = AsLogoutDirective;
exports.PasswordVerify = PasswordVerify;
exports.StartFromFilter = StartFromFilter;
function NavigationPrimary() {
  return {
    restrict: 'A',
    templateUrl: 'templates/nav-main.html'
  };
}

function AsLogoutDirective(identityService) {
  return {
    restrict: 'A',
    link: function link(scope, element, attrs) {
      element.bind('click', function () {
        var auth = identityService.logout();
      });
    }
  };
}

function PasswordVerify() {
  return {
    restrict: 'A',
    require: '?ngModel',
    link: function link(scope, elem, attrs, ngModel) {
      if (!ngModel) return; // do nothing if no ng-model

      // watch own value and re-validate on change
      scope.$watch(attrs.ngModel, function () {
        validate();
      });

      // observe the other value and re-validate on change
      attrs.$observe('passwordVerify', function (val) {
        validate();
      });

      var validate = function validate() {
        // values
        var val1 = ngModel.$viewValue;
        var val2 = attrs.passwordVerify;

        // set validity
        ngModel.$setValidity('passwordVerify', val1 === val2);
      };
    }
  };
}

function StartFromFilter() {
  return function (input, start) {
    if (input && input.length > 0) {
      start = +start;
      return input.slice(start);
    }

    return [];
  };
}

},{}],16:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IdentityApi = IdentityApi;
exports.RegistrationApi = RegistrationApi;
exports.AuthenticationApi = AuthenticationApi;
exports.DeepObjectApi = DeepObjectApi;
exports.ObservationApi = ObservationApi;
exports.DataIoApi = DataIoApi;
function IdentityApi(orbRestfulResource, orbRestfulProgressService) {
  var res = new orbRestfulResource('/api/account/identity', {}, { query: { isArray: false } });
  return new orbRestfulProgressService(res);
}

function RegistrationApi() {
  return {
    $get: function $get($resource) {
      return $resource('/api/account', {}, {
        register: { method: 'POST' }
      });
    }
  };
}

function AuthenticationApi() {
  return {
    $get: function $get($resource) {
      return $resource('/api/account/auth', {}, {
        login: { method: 'POST' },
        logout: { method: 'DELETE' }
      });
    }
  };
}

function DeepObjectApi(orbRestfulResource, orbRestfulProgressService) {
  var res = new orbRestfulResource('/api/deepobject/:id', {}, { query: { isArray: false } });
  return new orbRestfulProgressService(res);
}

function ObservationApi(orbRestfulResource, orbRestfulProgressService) {
  var res = new orbRestfulResource('/api/observation/:id', {}, { query: { isArray: false } });
  return new orbRestfulProgressService(res);
}

function DataIoApi(orbRestfulResource, orbRestfulProgressService) {
  var res = new orbRestfulResource('/api/write-disk', {}, { query: { isArray: false } });
  return new orbRestfulProgressService(res);
}

},{}],17:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = DeepObjectService;
function DeepObjectService(skyDeepObjectApi, logService, orbMessageService, orbProgressService) {
  return {
    empty: function empty() {
      return skyDeepObjectApi.empty();
    },

    all: function all(catalog) {
      var defer = skyDeepObjectApi.all({ catalog: catalog });
      return defer;
    },

    get: function get(id) {
      var defer = skyDeepObjectApi.find(id);
      return defer;
    },

    create: function create(object) {
      var defer = skyDeepObjectApi.save(object).then(function (response) {
        orbMessageService.success('New deep sky object successfully created.');
        return response;
      }, function (reason) {
        logService.displayErrorMessage(reason, 'Error while creating new deep sky object.');
      });

      return defer;
    }
  };
}

},{}],18:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = IdentityService;
function IdentityService($rootScope, $location, $log, logService, skyIdentityApi, skyAuthenticationApi, skyRegistrationApi, authService, orbMessageService) {
  var identity = {};

  var refresh = function refresh() {
    var defer = skyIdentityApi.all();
    defer.then(function (updatedIdentity) {
      if (updatedIdentity !== identity) {
        angular.copy(updatedIdentity, identity);
      }
    }, function (reason) {
      if (reason && reason.status == 401) {
        return;
      }

      logService.displayErrorMessage(reason, 'Could not load user information.');
    });

    return defer;
  };

  var ensure = function ensure() {
    if (identity && Object.keys(identity).length > 0) {
      return $q.when(identity);
    }

    return refresh();
  };

  return {
    data: identity,
    ensure: ensure,
    refresh: refresh,

    login: function login(data) {
      data = data || {};
      var auth = skyAuthenticationApi.login({
        email: data.email,
        password: data.password,
        persistent: data.persistent || false
      });

      auth.$promise.then(function () {
        authService.loginConfirmed();
      }, function (reason) {
        logService.displayErrorMessage(reason, 'Authentication failed.');
      });

      return auth.$promise;
    },

    logout: function logout() {
      var auth = skyAuthenticationApi.logout();

      auth.$promise.then(function () {
        if (identity !== {}) {
          angular.copy({}, identity);
        }

        $location.path('/');
        $rootScope.$broadcast('event:auth-logoutConfirmed', {});
        if (!$rootScope.$$phase) {
          $rootScope.$apply();
        }

        orbMessageService.success('You have been successfully signed out.');
        refresh();
      }, function (reason) {
        logService.displayErrorMessage(reason, 'An error occurred while signing out.');
      });

      return auth.$promise;
    },

    register: function register(data) {
      data = data || {};
      var registration = skyRegistrationApi.register({
        email: data.email,
        password: data.password,
        fullName: data.fullName
      });

      registration.$promise.then(function () {
        orbMessageService.success('Thanks and welcome to SkyObserver! You can now login and get started.');
      }, function (reason) {
        logService.displayErrorMessage(reason, 'Account with that email already exists.');
      });

      return registration.$promise;
    }
  };
}

},{}],19:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = LogService;
function LogService($log, orbMessageService) {
  return {
    displayErrorMessage: function displayErrorMessage(reason, defaultMessage) {
      var message = '';

      if (reason && reason.data && reason.data.errors) {
        message = reason.data.errors.map(function (error) {
          return error.message;
        }).join('. ') + '.';
      }

      if (!message) {
        message = defaultMessage;
      }

      $log.error(message);
      orbMessageService.error(message);
    }
  };
}

},{}],20:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = LookupService;
function LookupService() {
  var transparencies = [{
    value: 5,
    label: 'Transparent'
  }, {
    value: 4,
    label: 'Above Average'
  }, {
    value: 3,
    label: 'Average'
  }, {
    value: 2,
    label: 'Below Average'
  }, {
    value: 1,
    label: 'Poor'
  }];

  var seeings = [{
    "value": 5,
    "label": "Excellent"
  }, {
    "value": 4,
    "label": "Good"
  }, {
    "value": 3,
    "label": "Average"
  }, {
    "value": 2,
    "label": "Poor"
  }, {
    "value": 1,
    "label": "Bad"
  }];

  var catalogs = ["Messier", "Caldwell", "Herschel"];

  var constellations = ["Andromeda", "Antlia", "Apus", "Aquarius", "Aquila", "Ara", "Aries", "Auriga", "Boötes", "Caelum", "Camelopardalis", "Cancer", "Canes Venatici", "Canis Major", "Canis Minor", "Capricornus", "Carina", "Cassiopeia", "Centaurus", "Cepheus", "Cetus", "Chameleon", "Circinus", "Columba", "Coma Berenices", "Corona Borealis", "Corona Australis", "Corvus", "Crater", "Crus", "Cygnus", "Dorado", "Delphinus", "Draco", "Equuleus", "Eridanus", "Fornax", "Gemini", "Grus", "Hercules", "Horologium", "Hydra", "Hydrus", "Indus", "Lacerta", "Leo", "Leo Minor", "Lepus", "Libra", "Lupus", "Lynx", "Lyra", "Mensa", "Microscopium", "Monoceros", "Musca", "Norma", "Octans", "Ophiuchus", "Orion", "Pavo", "Pegasus", "Perseus", "Phoenix", "Pictor", "Pisces", "Piscis Austrinus", "Puppis", "Pyxis", "Reticulum", "Sagittarius", "Sagitta", "Scorpius", "Sculptor", "Scutum", "Serpens Caput", "Serpens Cauda", "Sextans", "Taurus", "Telescopium", "Triangulum", "Triangulum Australe", "Tucana", "Ursa Major", "Ursa Minor", "Vela", "Virgo", "Volans", "Vulpecula"];

  var dateOptions = {
    showWeeks: false,
    startingDay: 0
  };

  var altInputFormats = ["M!/d!/yyyy", "M!-d!-yyyy"];

  return {
    maxPageSize: 7,
    transparencies: transparencies,
    seeings: seeings,
    catalogs: catalogs,
    constellations: constellations,
    dateOptions: dateOptions,
    altInputFormats: altInputFormats
  };
}

},{}],21:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ObservationService;
function ObservationService(skyObservationApi, logService, orbMessageService, orbProgressService) {
  return {
    empty: function empty() {
      return skyObservationApi.empty();
    },

    all: function all() {
      var defer = skyObservationApi.all();
      return defer;
    },

    get: function get(id) {
      var defer = skyObservationApi.find(id);
      return defer;
    },

    create: function create(observation) {
      var defer = skyObservationApi.save(observation).then(function () {
        orbMessageService.success('Observation successfully recorded.');
      }, function (reason) {
        logService.displayErrorMessage(reason, 'Error while creating observation record.');
      });

      return defer;
    },

    save: function save(observation) {
      var defer = skyObservationApi.save(observation).then(function () {
        //orbMessageService.success("Observation successully updated.");
      }, function (reason) {
        logService.displayErrorMessage(reason, 'Error while attempting to update the observation record.');
      });

      return defer;
    }
  };
}

},{}]},{},[1])

