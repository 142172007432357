export default function LogService($log, orbMessageService) {
  return {
    displayErrorMessage: (reason, defaultMessage) => {
      let message = '';

      if (reason && reason.data && reason.data.errors) {
        message = reason.data.errors.map(error => error.message).join('. ') + '.';
      }

      if (!message) {
        message = defaultMessage;
      }

      $log.error(message);
      orbMessageService.error(message);
    }
  };
}
