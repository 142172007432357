export default function MessierListController($filter, skyDeepObjectService, skyLookupService, logService) {
  this.currentPage = 1;
  this.lookups = skyLookupService;

  this.pagingDisabled = false;

  skyDeepObjectService.all('Messier').then(response => {
    this.data = response;
    this.filtered = response.objects || [];
  }, reason => {
    logService.displayErrorMessage(reason, 'The Messier Catalog objects could not be loaded.');
  });
}
