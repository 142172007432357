export default function SolarListController($filter, skyDeepObjectService, skyLookupService, logService) {
    this.currentPage = 1;
    this.lookups = skyLookupService;
  
    this.pagingDisabled = false;
  
    skyDeepObjectService.all('Solar').then(response => {
      this.data = response;
      this.filtered = response.objects || [];
    }, reason => {
      logService.displayErrorMessage(reason, 'The Solar System objects could not be loaded.');
    });
}