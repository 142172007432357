export default function ObservationsController(skyObservationService, logService) {
  this.currentPage = 1;
  this.pagingDisabled = false;

  skyObservationService.all().then(response => {
    this.data = response.observations;
    this.filtered = response.observations || [];
  }, reason => {
    logService.displayErrorMessage(reason, 'Your observations could not be loaded.');
  });
}
