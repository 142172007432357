export default function ObservationService(skyObservationApi, logService, orbMessageService, orbProgressService) {
  return {
    empty: () => {
      return skyObservationApi.empty();
    },

    all: () => {
      let defer = skyObservationApi.all();
      return defer;
    },

    get: (id) => {
      let defer = skyObservationApi.find(id);
      return defer;
    },

    create: (observation) => {
      let defer = skyObservationApi.save(observation).then(() => {
        orbMessageService.success('Observation successfully recorded.');
      }, (reason) => {
        logService.displayErrorMessage(reason, 'Error while creating observation record.');
      });

      return defer;
    },

    save: (observation) => {
      let defer = skyObservationApi.save(observation).then(() => {
        //orbMessageService.success("Observation successully updated.");
      }, (reason) => {
        logService.displayErrorMessage(reason, 'Error while attempting to update the observation record.');
      });

      return defer;
    }
  };
}
