export default function LookupService() {
  let transparencies = [{
    value: 5,
    label: 'Transparent'
  }, {
    value: 4,
    label: 'Above Average'
  }, {
    value: 3,
    label: 'Average'
  }, {
    value: 2,
    label: 'Below Average'
  }, {
    value: 1,
    label: 'Poor'
  }];

  let seeings = [{
    "value": 5,
    "label": "Excellent"
  }, {
    "value": 4,
    "label": "Good"
  }, {
    "value": 3,
    "label": "Average"
  }, {
    "value": 2,
    "label": "Poor"
  }, {
    "value": 1,
    "label": "Bad"
  }];

  let catalogs = [
    "Messier",
    "Caldwell",
    "Herschel"
  ];

  let constellations = [
    "Andromeda",
    "Antlia",
    "Apus",
    "Aquarius",
    "Aquila",
    "Ara",
    "Aries",
    "Auriga",
    "Boötes",
    "Caelum",
    "Camelopardalis",
    "Cancer",
    "Canes Venatici",
    "Canis Major",
    "Canis Minor",
    "Capricornus",
    "Carina",
    "Cassiopeia",
    "Centaurus",
    "Cepheus",
    "Cetus",
    "Chameleon",
    "Circinus",
    "Columba",
    "Coma Berenices",
    "Corona Borealis",
    "Corona Australis",
    "Corvus",
    "Crater",
    "Crus",
    "Cygnus",
    "Dorado",
    "Delphinus",
    "Draco",
    "Equuleus",
    "Eridanus",
    "Fornax",
    "Gemini",
    "Grus",
    "Hercules",
    "Horologium",
    "Hydra",
    "Hydrus",
    "Indus",
    "Lacerta",
    "Leo",
    "Leo Minor",
    "Lepus",
    "Libra",
    "Lupus",
    "Lynx",
    "Lyra",
    "Mensa",
    "Microscopium",
    "Monoceros",
    "Musca",
    "Norma",
    "Octans",
    "Ophiuchus",
    "Orion",
    "Pavo",
    "Pegasus",
    "Perseus",
    "Phoenix",
    "Pictor",
    "Pisces",
    "Piscis Austrinus",
    "Puppis",
    "Pyxis",
    "Reticulum",
    "Sagittarius",
    "Sagitta",
    "Scorpius",
    "Sculptor",
    "Scutum",
    "Serpens Caput",
    "Serpens Cauda",
    "Sextans",
    "Taurus",
    "Telescopium",
    "Triangulum",
    "Triangulum Australe",
    "Tucana",
    "Ursa Major",
    "Ursa Minor",
    "Vela",
    "Virgo",
    "Volans",
    "Vulpecula"
  ];

  let dateOptions = {
    showWeeks: false,
    startingDay: 0
  };

  let altInputFormats = ["M!/d!/yyyy", "M!-d!-yyyy"];

  return {
    maxPageSize: 7,
    transparencies: transparencies,
    seeings: seeings,
    catalogs: catalogs,
    constellations: constellations,
    dateOptions: dateOptions,
    altInputFormats: altInputFormats
  };
}
