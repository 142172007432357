export default function IdentityController($scope, $log, $uibModal, identityService) {
  let pendingLogin = false;

  this.user = identityService.data;
  identityService.ensure();

  $scope.$on('event:auth-loginRequired', (event, args) => {
    if (!pendingLogin) {
      pendingLogin = true;
      showLogin();
    }
  });

  $scope.$on('event:auth-loginConfirmed', (event, args) => {
    $log.info('Login confirmed');
    pendingLogin = false;
    identityService.refresh();
  });

  $scope.$on('event:auth-loginCancelled', (event, args) => {
    $log.info('Login cancelled');
    pendingLogin = false;
  });

  let showLogin = () => {
    pendingLogin = true; // just in case somebody bypasses the 401 auth event and uses the Login button

    let loginModalInstance = $uibModal.open({
      templateUrl:  'templates/login-modal.html',
      controller:   'skyLoginModalController',
      controllerAs: 'loginController',
      windowClass:  'login-modal',
      backdrop:     'static',
      keyboard:     false
    });

    return loginModalInstance.result;
  };
}
