export default function LoginController($q, $location, identityService) {
  this.credentials = {
    persistent: false,
    email: '',
    password: ''
  };

  this.login = () => {
    let auth = identityService.login({
      email: this.credentials.email,
      password: this.credentials.password,
      persistent: this.credentials.persistent || false
    });

    $q.when(auth).then(() => {
      $location.path('/');
    });
  };
}
